import React, { FC } from 'react';
import { useQuery, useMutation } from 'react-apollo';
import { Button, Divider, Icon } from 'semantic-ui-react';
import moment from 'moment';

import ErrorDisplay from 'components/ErrorDisplay';
import Loader from 'components/Loader';
import ConfirmButton from 'components/ConfirmButton';

import FETCH_ORDER_DETAIL from 'graphql/queries/fetchOrderDetail.graphql';
import UPDATE_ORDER from 'graphql/mutations/updateOrder.graphql';

import { getFullStatus } from 'utils/getStatus';

import theme from './theme.scss';

type OrderPanelProps = {
  orderId: string;
  orderToDeliver: string;
  toggleQRReader: (value: boolean) => void;
};

type OrderQueryResponse = {
  order: {
    id: string;
    referenceId: string;
    status: string;
    createdAt: string;
    orderedBy: {
      fullName: string;
      phoneNumber: string;
    };
    notes: string;
    spot: {
      name: string;
    };
    dishes: {
      id: string;
      name: string;
      quantity: number;
      price: number;
      combinations: {
        option: {
          id: string;
          name: string;
          price: string;
        };
        addOns: {
          id: string;
          name: string;
          price: string;
        }[];
      }[];
    }[];
    totalPrice: number;
  };
};

type OrderQueryVariables = {
  orderId: string;
};

const OrderContent: FC<{
  orderId: string;
  orderToDeliver: string;
  toggleQRReader: (value: boolean) => void;
}> = ({ orderId, orderToDeliver, toggleQRReader }) => {
  const { loading, error, data } = useQuery<OrderQueryResponse, OrderQueryVariables>(
    FETCH_ORDER_DETAIL,
    { variables: { orderId } }
  );

  const [updateOrder] = useMutation(UPDATE_ORDER);

  if (loading)
    return (
      <div className={theme.orderPanel}>
        <Loader />
      </div>
    );

  if (error || !data || !data.order)
    return (
      <div className={theme.orderPanel}>
        <ErrorDisplay />
      </div>
    );

  const order = data.order;

  function acceptButton() {
    if (order.status === 'CANCELLED') return null;

    const disabled =
      order.status === 'INPROGRESS' || order.status === 'READY' || order.status === 'DELIVERED';

    return (
      <ConfirmButton
        text="ACCEPT ORDER"
        type="positive"
        disabled={disabled}
        divider={disabled}
        action={() =>
          updateOrder({
            variables: { orderId, status: 'INPROGRESS' },
          })
        }
      />
    );
  }

  function cancelButton() {
    if (order.status === 'CREATED' || order.status === 'PAID' || order.status === 'BILLED') {
      return (
        <ConfirmButton
          text="CANCEL"
          type="negative"
          action={() =>
            updateOrder({
              variables: { orderId, status: 'READY' },
            })
          }
        />
      );
    }
    return null;
  }

  function readyButton() {
    if (order.status === 'INPROGRESS' || order.status === 'READY' || order.status === 'DELIVERED') {
      const disabled = order.status === 'READY' || order.status === 'DELIVERED';

      return (
        <ConfirmButton
          text="READY FOR PICKUP"
          type="positive"
          disabled={disabled}
          divider={disabled}
          action={() =>
            updateOrder({
              variables: { orderId, status: 'READY' },
            })
          }
        />
      );
    }
    return null;
  }

  function deliveredButton() {
    if (order.status === 'READY' || order.status === 'DELIVERED') {
      if (order.status !== 'DELIVERED' && order.id !== orderToDeliver) {
        return (
          <Button onClick={() => toggleQRReader(true)} fluid>
            OPEN QR CAMERA
          </Button>
        );
      }

      const disabled = order.status === 'DELIVERED';

      return (
        <ConfirmButton
          text="DELIVERED"
          type="positive"
          disabled={disabled}
          divider={false}
          action={() =>
            updateOrder({
              variables: { orderId, status: 'DELIVERED' },
            })
          }
        />
      );
    }
    return null;
  }

  return (
    <div className={theme.orderPanel}>
      <div className={theme.header}>
        <h1>{`Order: ${order.referenceId}`}</h1>
        <span>{getFullStatus(order.status)}</span>
      </div>
      <Divider />
      <div className={theme.content}>
        <div className={theme.leftSection}>
          <h2>ITEMS</h2>
          {order.dishes.map(dish => (
            <React.Fragment>
              <div className={theme.item} key={dish.id}>
                <span className={theme.quantity}>{dish.quantity}</span>
                <span className={theme.multiplier}>X</span>
                <span className={theme.name}>{dish.name}</span>
                <span>
                  <Icon
                    name="volume up"
                    size="big"
                    color="blue"
                    onClick={() => {
                      const msg = new SpeechSynthesisUtterance(dish.name);
                      msg.voice = speechSynthesis
                        .getVoices()
                        .filter(voice => voice.name === 'Lekha')[0];
                      speechSynthesis.speak(msg);
                    }}
                  />
                </span>
              </div>
              {dish.combinations &&
                dish.combinations.map(c => (
                  <div className={theme.combinationsContainer}>
                    <p>Option: {c.option ? c.option.name : ''}</p>
                    <p>AddOns: {c.addOns.map(a => a.name).join(', ')}</p>
                  </div>
                ))}
            </React.Fragment>
          ))}
        </div>
        <div className={theme.rightSection}>
          {acceptButton()}
          {cancelButton()}
          {readyButton()}
          {deliveredButton()}
          <div className={theme.information}>
            <h3>INFORMATION</h3>
            <div className={theme.field}>
              <p className={theme.label}>ORDERED BY</p>
              <p className={theme.value}>{order.orderedBy ? order.orderedBy.fullName : ''}</p>
            </div>
            <div className={theme.field}>
              <p className={theme.label}>PHONE NUMBER</p>
              <p className={theme.value}>{order.orderedBy ? order.orderedBy.phoneNumber : ''}</p>
            </div>
            <div className={theme.field}>
              <p className={theme.label}>ORDER TIME</p>
              <p className={theme.value}>
                {moment(order.createdAt).format('HH:mm a DD MMMM YYYY')}
              </p>
            </div>
            <div className={theme.field}>
              <p className={theme.label}>TOTAL PRICE</p>
              <p className={theme.value}>₹ {order.totalPrice}</p>
            </div>
            <div className={theme.field}>
              <p className={theme.label}>SPOT NAME</p>
              <p className={theme.value}>{order.spot ?order.spot.name : ''}</p>
            </div>
            <div className={theme.field}>
              <p className={theme.label}>ADDITIONAL INFORMATION</p>
              <p className={theme.value}>{order.notes}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const OrderPanel: FC<OrderPanelProps> = ({ orderId, orderToDeliver, toggleQRReader }) => {
  if (orderId === '') {
    return (
      <div className={theme.orderPanel}>
        <div className={theme.noSelection}>
          <div className={theme.content}>
            <Icon name="arrow alternate circle left outline" size="huge" />
            <span>Select an order to view it's details.</span>
          </div>
        </div>
      </div>
    );
  }

  return (
    <OrderContent
      orderId={orderId}
      orderToDeliver={orderToDeliver}
      toggleQRReader={toggleQRReader}
    />
  );
};

export default OrderPanel;
